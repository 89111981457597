import React from "react"
import tw, { styled } from "twin.macro"

const Wrapper = styled.section`
    ${tw`w-full m-0 p-0 bg-psb-dark-gray-400 text-white`}
`

const Container = styled.div`
    ${tw`container w-2/3 mx-auto`}
`

const Section = styled.div`
    ${tw`flex flex-col items-center my-12 text-center`}
`

const Title = styled.p`
    ${tw`text-white text-5xl text-center font-semibold leading-tight`}
`

const SubTitle = styled(Title)`
    ${tw`text-xl`}
`

const SubSubTitle = styled(Title)`
    ${tw`text-base mt-10`}
`

const Highlight = styled.span`
    ${tw`text-psb-orange-400`}
`

const MainHero = () => {
    return(
        <Wrapper>
            <Container>
                <Section>
                    <Title>MOBILE PHOTOGRAPHY <Highlight>FUNDAMENTALS</Highlight></Title>
                    <SubTitle>iPhone photography and videography news, tips, tutorials and reviews to help grow your skills</SubTitle>
                    <SubSubTitle><Highlight>Read about how to take great photos and videos with your iPhone, learn more advanced shooting techniques, and recommended software and accessories to enhance your skills further</Highlight></SubSubTitle>
                </Section>
            </Container>
        </Wrapper>
    )
}

export default MainHero