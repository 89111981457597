import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section/section"
import MainHero from "../components/main-hero"
import ArticleSmallCard from "../components/article/article-small-card"
import ArticleLargeCard from "../components/article/article-large-card"
import SectionSubscribe from "../components/section/section-subscribe"
import SectionSiteFeatures from "../components/section/section-site-features"
import SectionArticles from "../components/section/section-articles"

const ArticleCards = styled.div`
    ${tw`flex flex-wrap -mx-3 lg:-mx-6 -mt-6 mt-1`}
`

const Decoration = styled.span`
    ${tw`underline`}
    text-decoration-color: #ff9f0A;
`

const IndexPage = ({ data, location }) => {
    const featuredArticles = data.featured.edges
    const latestArticles = data.latestArticles.edges

    return(
        <Layout>
            <SEO
                title="Home"
                pathname={location.pathname}
            />
            <MainHero/>
            <Section>
                <h2>Featured <Decoration>Articles</Decoration></h2>
                <ArticleCards>
                    {featuredArticles.map(({ node }) => (
                        <ArticleLargeCard
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            categories={node.frontmatter.categories}
                            tags={node.frontmatter.tags}
                            featuredImage={node.frontmatter.featuredImage.childImageSharp.fluid}
                            slug={node.fields.slug} />
                    ))}
                </ArticleCards>
            </Section>
            <SectionSiteFeatures />
            <SectionArticles>
                <h2>Latest <Decoration>Articles</Decoration></h2>
                <ArticleCards>
                    {latestArticles.map(({ node }) => (
                        <ArticleSmallCard
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            categories={node.frontmatter.categories}
                            tags={node.frontmatter.tags}
                            featuredImage={node.frontmatter.featuredImage.childImageSharp.fluid}
                            slug={node.fields.slug} />
                    ))}
                </ArticleCards>
            </SectionArticles>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query {
        latestArticles: allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { published: { eq: true } } }
            limit: 16
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        categories
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        featured: allMdx(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { featured: { eq: true }, published: { eq: true } } }
            limit: 4
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        categories
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    excerpt(pruneLength: 250)
                }
            }
        }
    }
`