import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

const Wrapper = styled.article`
    ${tw`w-full md:w-1/2 lg:w-1/2 xl:w-1/3 p-3 md:p-6`}
`

const Container = styled.div`
    ${tw`relative h-full rounded-lg overflow-hidden bg-psb-dark-light-gray-400 border-psb-dark-gray-400 shadow-lg`}
`

const Title = styled.h4`
    ${tw`px-4 pb-12 pt-2`}
`

const DateRow = styled.div`
    ${tw`absolute  w-full bottom-0`}
`

const Divider = styled.hr`
    ${tw`p-0 border-psb-dark-gray-400`}
`

const Meta = styled.div`
    ${tw`text-psb-orange-400 text-xs font-medium p-2`}
`

const Overlay = styled.div`
     ${tw`z-10 absolute m-3 px-3 py-0 text-base rounded-md font-semibold bg-psb-blue-400 text-white`}
     right: 0;
 `

const ArticleList = ({ title, date, categories, tags, featuredImage, slug }) => {
    return(
        <Wrapper>
            <Container>
            <Link to={slug}>
                <Overlay>
                    {categories.map((category, index, array) => {
                        return (
                            <>
                                {category.toUpperCase()}{ index < array.length - 1 ? ", " : "" }
                            </>
                        )
                    })}
                </Overlay>
                <Img fluid={featuredImage} />
                <Title>{title}</Title>
                <DateRow>
                    <Divider />
                    <Meta>
                        {date}
                    </Meta>
                </DateRow>
                </Link>
            </Container>
        </Wrapper>
    )
}

export default ArticleList