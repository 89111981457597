import React from "react"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faHandHoldingUsd, faInfo, faInfoCircle, faListUl, faNewspaper, faQuestion } from "@fortawesome/free-solid-svg-icons"

const OuterWrapper = styled.section`
    ${tw`w-full mx-0 p-0 bg-psb-dark-gray-400`}
`

const OuterContainer = styled.div`
    ${tw`container w-11/12 mx-auto px-5 py-8`}
`

const Wrapper = styled.div`
    ${tw`w-full`}
`

const Container = styled.div`
    ${tw`container flex flex-wrap mx-auto text-center`}
`

const CardWrapper = styled.div`
    ${tw`w-full md:w-1/2 lg:w-1/4 p-3 md:p-6`}
`

const CardContainer = styled.div`
    ${tw`container mx-auto text-center`}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-4 text-psb-blue-400 text-4xl md:text-5xl`}
`

const Description = styled.p`
    ${tw`md:text-lg`}
`

const SectionAppFeatures = () => {
    return(
        <OuterWrapper>
            <OuterContainer>
                <Wrapper>
                    <Container>
                        <CardWrapper>
                            <CardContainer>
                                <StyledFontAwesomeIcon icon={faNewspaper} />
                                <h3>News</h3>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <StyledFontAwesomeIcon icon={faInfoCircle} />
                                <h3>Tips</h3>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <StyledFontAwesomeIcon icon={faListUl} />
                                <h3>Reviews</h3>
                            </CardContainer>
                        </CardWrapper>
                        <CardWrapper>
                            <CardContainer>
                                <StyledFontAwesomeIcon icon={faCheckCircle} />
                                <h3>Recommendations</h3>
                            </CardContainer>
                        </CardWrapper>
                    </Container>
                </Wrapper>
            </OuterContainer>
        </OuterWrapper>
    )
}

export default SectionAppFeatures